import React, { useState, useEffect } from "react";
import commonColor from "../../../theme/commonColor";
import { connect } from "react-redux";
import UserActions from "../../../redux/user";
import Transactions from "./transactions";
import TutorStatistics from "../../tutor/profile/tutorStatistics";
import { ReactComponent as Stat_icon } from "../../../assets/images/profile/statistic_icon.svg";
import { ReactComponent as TransactionIcon } from "../../../assets/images/profile/transactions.svg";
import { ReactComponent as TutortypeIcon } from "../../../assets/images/profile/tutor-type.svg";
import { ReactComponent as SwotWalletIcon } from "../../../assets/images/profile/swotwallet.svg";
import {
  getStudentPaymentReport,
  getTutorTeachingPercentages,
} from "../../../services/api";
import TutorTypePanel from "../../tutor/profile/tutorTypePanel";
import SubscriptionBoard from "../../tutor/profile/subscriptionBoard";

const WalletPanel = ({ user, saveUser }) => {
  const [topSelected, setTopSelected] = useState("Subscription");
  const [paymentReports, setPaymentReports] = useState([]);
  const [teachingPercents, setTeachingPercents] = useState([]);

  useEffect(() => {
    const getTransactions = async () => {
      const res = await getStudentPaymentReport(user.uid);
      if (res?.data?.status && res.data.status === "SUCCESS") {
        setPaymentReports(res.data.data ?? []);
      }
    };
    getTransactions();
    getTutorPercentages();
  }, []);

  const getTutorPercentages = async () => {
    const result = await getTutorTeachingPercentages(user.uid);
    if (result) {
      console.log(
        "TutorTypePanel - useEffect - got tutor Percentages",
        result.data
      );
      setTeachingPercents(result.data);
    }
  };

  const renderTopSelectedTab = () => {
    if (topSelected === "Subscription") {
      return (
        <div className="w-full h-full flex justify-center items-center py-4 px-1">
          <SubscriptionBoard user={user} />
        </div>
      );
    } else if (topSelected === "Transactions") {
      return (
        <div className="h-full w-full flex justify-center items-center">
          <Transactions user={user} />
        </div>
      );
    } else if (topSelected === "Statistics") {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <TutorStatistics user={user} transactions={paymentReports} />
        </div>
      );
    } else if (topSelected === "TutorType") {
      return (
        <div className="w-full h-full flex justify-center items-center">
          <TutorTypePanel user={user} teachingPercents={teachingPercents} />
        </div>
      );
    }
  };

  return (
    <div className="h-full w-full px-2 flex flex-col justify-start">
      <div className="w-full flex justify-center heightBoxHeader">

      <button
              className="w-1/4 pt-2 px-2 pb-1 mx-3 text-center text-white text-sm focus:outline-none"
              style={{
                backgroundColor:
                  topSelected === "Subscription"
                    ? commonColor.deepPink
                    : "transparent",
                borderTopLeftRadius: "1.5rem",
                borderTopRightRadius: "1.5rem",
              }}
              onClick={() => setTopSelected("Subscription")}
            >
              <div className="flex justify-center items-center">
                <SwotWalletIcon className="w-4" />
                <p className="hidden ml-2 md:block">Subscription</p>
              </div>
            </button>

        <button
          className="w-1/4 pt-2 px-2 pb-1 mx-3 text-center text-white text-sm focus:outline-none"
          style={{
            backgroundColor:
              topSelected === "Transactions"
                ? commonColor.deepPink
                : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setTopSelected("Transactions")}
        >
          <div className="flex justify-center items-center">
            <TransactionIcon className="w-4" />
            <p className="hidden ml-2 md:block">Transactions</p>
          </div>
        </button>

        <button
          className="w-1/4 pt-2 px-2 pb-1 mx-3 text-center text-white text-sm focus:outline-none"
          style={{
            backgroundColor:
              topSelected === "Statistics"
                ? commonColor.deepPink
                : "transparent",
            borderTopLeftRadius: "1.5rem",
            borderTopRightRadius: "1.5rem",
          }}
          onClick={() => setTopSelected("Statistics")}
        >
          <div className="flex justify-center items-center">
            <Stat_icon className="w-5" />
            <p className="hidden ml-2 md:block">Statistics</p>
          </div>
        </button>

        {(user.accountType === "Tutor" || user.quizDone) && (
          <button
            className="w-1/4 pt-2 px-2 pb-1 mx-3 text-center text-white text-sm focus:outline-none"
            style={{
              backgroundColor:
                topSelected === "TutorType"
                  ? commonColor.deepPink
                  : "transparent",
              borderTopLeftRadius: "1.5rem",
              borderTopRightRadius: "1.5rem",
            }}
            onClick={() => setTopSelected("TutorType")}
          >
            <div className="flex justify-center items-center">
              <TutortypeIcon className="w-5" />
              <p className="hidden ml-2 md:block">
                {user.accountType === "Tutor" ? "Tutor Type" : "Learner Type"}
              </p>
            </div>
          </button>
        )}
      </div>
      <div
        className="w-full bg-white darker_shadow overflow-y-scroll flex-grow heightBoxContent"
        style={{
          borderRadius: "1.5rem",
          // height: "calc(100% - 3rem)"
        }}
      >
        {renderTopSelectedTab()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

const mapDispatchToProps = (dispatch) => ({
  saveUser: (user) => dispatch(UserActions.saveUserRequest(user)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletPanel);
